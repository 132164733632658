import React, { Component } from 'react';
import Toast from '../components/Toast';
import LoginState from '../components/LoginState';
import axios from 'axios';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
// const docStatusIndex = [
//   'Not Reviewed',
//   'Approved',
//   'Inactive',
//   'Incomplete',
//   'Missing Pages',
//   'Unacceptable',
//   'Duplicate',
//   'Illigible',
//   'Ready To Ship',
//   'Reviewed',
// ];

const relatedDocumentCategories = [
  '1003',
  '1008',
  'Underwriting Approval',
  'Credit Report',
  'AUS Findings',
  'TRID',
  'Appraisal Report',
  'Lock Confirmation',
  'Closing Package',
  'Funding Figures',
  'Payment Letter',
  'Goodbye Letter',
];

const brokerDocumentsCategories = [
  'Application',
  'Credit',
  'Income',
  'Assets',
  'Subject Property',
  'REO',
  'Title/Escrow',
  'TRID',
  'Miscellaneous',
  'Bulk Uploads',
  'Submission Packages',
];

// finds what category to put file in based on document type
const relatedDocumentCatIndex = {
  1003: '1003',
  1008: '1008',
  'Conditional Loan Approval': 'Underwriting Approval',
  'AUS Findings': 'AUS Findings',
  'DU Findings': 'AUS Findings',
  'LP Findings': 'AUS Findings',
  'Underwriting Findings': 'AUS Findings',
  'Loan Product Advisor Findings': 'AUS Findings',
  'Credit Report': 'Credit Report',
  'Initial Disclosures': 'TRID',
  'Locked LE': 'TRID',
  'Revised LE': 'TRID',
  'Initial CD': 'TRID',
  'Appraisal Report (1004)': 'Appraisal Report',
  'Appraisal Condominium Report (1073)': 'Appraisal Report',
  'Appraisal Income Property Report (1025)': 'Appraisal Report',
  'Appraisal Final Report (1004D)': 'Appraisal Report',
  'Appraisal Desk Review': 'Appraisal Report',
  'Appraisal Field Review': 'Appraisal Report',
  'Single Family Comparable Rent Schedule (1007)': 'Appraisal Report',
  'Operating Income Statement (216)': 'Appraisal Report',
  'Comparable Rent and Operating Income': 'Appraisal Report',
  'Appraisal Recertification': 'Appraisal Report',
  'Lock Confirmation': 'Lock Confirmation',
  'Closing Package': 'Closing Package',
  'Funding Figures': 'Funding Figures',
  'Payment Letter': 'Payment Letter',
  'Goodbye Letter': 'Goodbye Letter',
};

// finds the icon to show based on file category
const relatedDocumenImages = {
  1003: '1003',
  1008: '1008',
  'Underwriting Approval': 'uw-approval',
  'AUS Findings': 'aus',
  'Credit Report': 'cr',
  TRID: 'disclosures-trid',
  'Appraisal Report': 'appraisal-report',
  'Lock Confirmation': 'rate-lock',
  'Closing Package': 'closing-package',
  'Payment Letter': 'payment-letters',
  'Goodbye Letter': 'goodbye-letter',
  'Funding Figures': 'funding-sources',
};

const DocumentCard = ({ category, files = [], download }) => {
  // because payment letters are named with the month and year, we need to sort them differently
  const orderedFiles =
    category === 'Payment Letter'
      ? files.sort((a, b) =>
          a.documentName.split('/').join('/01/') <
          b.documentName.split('/').join('/01/')
            ? 1
            : -1
        )
      : files.sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1));
  return (
    <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-4 mb-md-4 position-relative">
      <div className="card h-100 text-start border-0">
        <div
          className={`card-body text-center dropdown-toggle ${
            !files.length && 'disabled opacity-25'
          }`}
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src={`/images/doc-${relatedDocumenImages[category]}.svg`}
            alt="document-thumbnail"
          />
          <h1 className="mb-1 h5 text-wrap">{category}</h1>
          <div className="dropdown">
            <ul className="dropdown-menu dropdown-menu-end">
              {orderedFiles.map(file => (
                <li key={file.fileDataID}>
                  <button
                    className="dropdown-item d-flex justify-content-between align-items-center"
                    onClick={() => download(file.documentID)}
                  >
                    <div className="d-flex align-items-center">
                      <i className="bi bi-download me-3 fs-5"></i>
                      {file.documentName}
                    </div>
                    <span className="text-black-50 ms-4">
                      {moment(file.dateCreated).format('MM/DD/YY')}
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const UploadedDocs = ({ type, files = [], download }) => {
  const targetTitle = type.split(' ').join('-').split('/').join('-');
  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className="accordion-button collapsed"
          disabled={files.length === 0}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#accordion-${targetTitle}`}
          aria-controls={targetTitle}
        >
          <span
            className={`badge rounded-pill ${
              files.length === 0 ? 'bg-secondary' : 'bg-primary'
            } me-2`}
            style={{ width: '30px' }}
          >
            {files.length}
          </span>
          {type}
        </button>
      </h2>
      <div
        id={`accordion-${targetTitle}`}
        className="accordion-collapse collapse"
      >
        <div className="accordion-body">
          <div className="list-group list-group-flush">
            {files.map((file, index) => (
              <button
                className="list-group-item list-group-item-action"
                key={`${index}-${file.name}`}
                onClick={() => download(file.documentID)}
              >
                {file.documentName}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

class LoanDocs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docList: [],
      searchLog: [],
      activeTab: 'Related Documents',
      successMessage: '',
    };
  }

  componentDidMount() {
    this.PageLoad(this.props.match.params.id);
  }

  async GetSearchLog(fileID) {
    // now update server with this data
    const response = axios.get('/getUpdatedSearchLog', {
      params: {
        pFileID: fileID,
        pUserID: LoginState.userId,
      },
    });
    var resultData = (await response).data;
    this.setState({ searchLog: resultData });
  }

  PageLoad(fileID) {
    this.setState({ docList: [] });
    this.GetSearchLog(fileID);
    this.getLoanFileList(fileID);
  }

  async getLoanFileList(fileID) {
    const response = axios.get('/getLoanFileList', {
      params: { pFileID: fileID },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    this.setState({ docList: resultData });
  }

  async downloadLoanFile(docID) {
    this.setState({ successMessage: 'Downloading File\nPlease Wait!' });
    const response = axios.get('/DownloadLoanFile', {
      params: {
        pFileID: this.props.match.params.id,
        pDocumentID: docID,
      },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    // if we couldn't get a file back, abort
    if (!resultData) {
      alert('Error: File Not Found');
      return;
    }

    var binary = atob(resultData.documentData);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)]);

    // this code below will download the file we selected
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = resultData.documentName + '.' + resultData.documentExension;
    a.click();
    URL.revokeObjectURL(url);

    this.setState({ successMessage: '' });
  }

  renderDocs = () => {
    // If there are no documents, return a message
    if (!this.state.docList) {
      return (
        <div className="alert alert-light" role="alert">
          Something went wrong, please refresh page.
        </div>
      );
    }
    // get documents for active tab.
    if (this.state.activeTab === 'Related Documents') {
      const filteredDocuments = this.state.docList.reduce((acc, curr) => {
        const category = relatedDocumentCatIndex[curr.documentType];
        if (curr.documentCategory !== 'BROK' && category) {
          acc[category] ? acc[category].push(curr) : (acc[category] = [curr]);
        }
        return acc;
      }, {});

      return relatedDocumentCategories.map((key, index) => (
        <DocumentCard
          key={key + index}
          category={key}
          files={filteredDocuments[key]}
          download={id => this.downloadLoanFile(id)}
        />
      ));
    } else {
      const filteredDocuments = this.state.docList.reduce((acc, curr) => {
        if (curr.documentCategory === 'BROK') {
          const code = curr.documentName.slice(0, 3);
          let propName = '';
          if (code >= 100 && code <= 199) propName = 'Application';
          if (code >= 200 && code <= 299) propName = 'TRID';
          if (code >= 300 && code <= 399) propName = 'Credit';
          if (code >= 400 && code <= 499) propName = 'Income';
          if (code >= 500 && code <= 599) propName = 'Assets';
          if (code >= 600 && code <= 649) propName = 'Subject Property';
          if (code >= 650 && code <= 699) propName = 'REO';
          if (code >= 700 && code <= 799) propName = 'Miscellaneous';
          if (code >= 800 && code <= 899) propName = 'Title/Escrow';
          if (code >= 900 && code <= 949) propName = 'TRID';
          if (code === 'BUL') propName = 'Bulk Uploads';
          if (code === 'Sub') propName = 'Submission Packages';
          if (!propName) propName = 'Miscellaneous';
          acc[propName] ? acc[propName].push(curr) : (acc[propName] = [curr]);
        }
        return acc;
      }, {});
      return (
        <div className="accordion accordion-flush" id="accordionExample">
          {brokerDocumentsCategories.map(key => (
            <UploadedDocs
              key={key}
              type={key}
              files={filteredDocuments[key]}
              download={id => this.downloadLoanFile(id)}
            />
          ))}
        </div>
      );
    }
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="h4">
              DOCUMENTS
              <button
                className="btn btn-text dropdown-toggle"
                style={{ marginLeft: '5px' }}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-list"></i>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <p className="dropdown-item mb-0 disabled">Recent Files</p>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {this.state.searchLog.length > 0 ? (
                  <>
                    {this.state.searchLog.map(file => (
                      <li key={file.encodedFileID}>
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            this.props.history.push(
                              '/pipeline/documents/' + file.encodedFileID
                            );
                            this.PageLoad(file.encodedFileID);
                          }}
                        >
                          {file.encodedFileID} ({file.borrower})
                        </span>
                      </li>
                    ))}
                  </>
                ) : (
                  <li>
                    <p className="dropdown-item mb-0 disabled">
                      No Recent Files
                    </p>
                  </li>
                )}
              </ul>
            </h1>
          </div>
          <div className="col-12">
            <ul className="nav nav-tabs nav-fill">
              <li className="nav-item" style={{ cursor: 'pointer' }}>
                <span
                  className={`nav-link ${
                    this.state.activeTab === 'Related Documents' ? 'active' : ''
                  }`}
                  onClick={() =>
                    this.setState({ activeTab: 'Related Documents' })
                  }
                >
                  Related Documents
                </span>
              </li>
              <li className="nav-item" style={{ cursor: 'pointer' }}>
                <span
                  className={`nav-link ${
                    this.state.activeTab === 'Uploaded Documents'
                      ? 'active'
                      : ''
                  }`}
                  onClick={() =>
                    this.setState({ activeTab: 'Uploaded Documents' })
                  }
                >
                  Uploaded Documents
                </span>
              </li>
            </ul>
          </div>
          <div className="col-12 mt-3">
            <div className="row">{this.renderDocs()}</div>
          </div>
        </div>
        {this.state.successMessage && (
          <Toast
            type="success"
            title={this.state.successMessage}
            onClose={() => this.setState({ successMessage: '' })}
          />
        )}
      </div>
    );
  }
}
export default withRouter(LoanDocs);
