import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { GlobalStyle, Theme } from './theme';
import { ThemeProvider } from 'styled-components';
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout';
import ScrollToTop from './components/ScrollToTop';
// ROUTES
import Dashboard from './containers/Dashboard';
import Help from './containers/Help';
import LenderPanel from './containers/LenderPanel';
import Pipeline from './containers/Pipeline';
import Pricing from './containers/Pricing';
import Resources from './containers/Resources';
import LoanDocs from './containers/LoanDocs';
import Settings from './containers/Settings';
import GeocodeFinder from './containers/GeocodeFinder';
import LoanContacts from './containers/LoanContacts';
import ProgramComparison from './containers/ProgramComparison';
import Restricted from './containers/Restricted';
import AppraisalFinder from './containers/AppraisalFinder';
import Landing from './containers/Landing/index';
import TimedOut from './containers/TimedOut';
import ForgotPassword from './containers/ForgotPassword';
import Closing from './components/Closing';
import EscrowConditions from './components/EscrowConditions';
import EscrowSearch from './components/EscrowSearch';
import Forms from './components/Forms';
import ImportantDates from './components/ImportantDates';
import LoanSummary from './components/LoanSummary';
import NewLoan from './components/RegisterLoan/NewLoan';
import ProductMatrix from './components/ProductMatrix';
import RateLock from './components/RateLock';
import Submission from './components/Submission';
import StatusBoard from './components/StatusBoard';
import Wizard1003 from './components/Wizard1003';
import WizardSubmission from './components/WizardSubmission';
import Messages from './components/Messages';
import AssociatedEmail from './components/AssociatedEmail';
import EmailSearch from './components/EmailSearch';
import Admin from './components/Admin';
import AdminMessage from './components/AdminMessage';
import DownloadFile from './components/DownloadFile';
import UploadPdf from './components/UploadPdf';
import FileDetails from './components/FileDetails/FileDetails';
import DailyRates from './components/DailyRates';
import PageRedirect from './components/PageRedirect';
import LoginState from './components/LoginState';
import AppraisalOrder from './components/AppraisalOrder';
import Circumstance from './components/Circumstance';
import Conditions from './components/Conditions';
import LoanWizard from './components/LoanWizard';
import DailyCheck from './components/DailyCheck';

const App = () => {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          {/* PUBLIC */}
          <Route exact path="/">
            <PublicLayout>
              <Landing />
            </PublicLayout>
          </Route>
          <Route exact path="/compare-programs">
            <PublicLayout>
              <ProgramComparison />
            </PublicLayout>
          </Route>
          <Route exact path="/forgot-password">
            <PublicLayout>
              <ForgotPassword />
            </PublicLayout>
          </Route>
          <Route exact path="/geoid-validator">
            <PublicLayout>
              <GeocodeFinder />
            </PublicLayout>
          </Route>
          <Route exact path="/timedout">
            <PublicLayout>
              <TimedOut />
            </PublicLayout>
          </Route>
          <Route path="/dailycheck">
            <PublicLayout>
              <DailyCheck />
            </PublicLayout>
          </Route>
          <Route path="/download">
            <PublicLayout>
              <DownloadFile />
            </PublicLayout>
          </Route>
          <Route path="/escrow">
            <PublicLayout>
              <EscrowSearch />
            </PublicLayout>
          </Route>
          <Route path="/escrow-file/:id">
            <PublicLayout>
              <EscrowConditions />
            </PublicLayout>
          </Route>
          <Route path="/loan-wizard/:pg">
            <PublicLayout>
              <LoanWizard />
            </PublicLayout>
          </Route>

          {/* NEW LOAN */}
          <Route path="/new-loan">
            <PrivateLayout>
              <NewLoan />
            </PrivateLayout>
          </Route>

          {/* PIPELINE */}
          <Route path="/pipeline/:sub" exact>
            <PrivateLayout>
              <Pipeline />
            </PrivateLayout>
          </Route>
          <Route path="/pipeline/appraisals/:id">
            <PrivateLayout>
              <AppraisalOrder />
            </PrivateLayout>
          </Route>
          <Route path="/pipeline/change-in-circumstance/:id">
            <PrivateLayout>
              <Circumstance />
            </PrivateLayout>
          </Route>
          <Route path="/pipeline/change-of-circumstance/:id">
            <PrivateLayout>
              <Circumstance />
            </PrivateLayout>
          </Route>
          <Route path="/pipeline/closing/:id">
            <PrivateLayout>
              <Closing />
            </PrivateLayout>
          </Route>
          <Route path="/pipeline/conditions/:id">
            <PrivateLayout>
              <Conditions />
            </PrivateLayout>
          </Route>
          <Route path="/pipeline/contacts/:id">
            <PrivateLayout>
              <LoanContacts />
            </PrivateLayout>
          </Route>
          <Route path="/pipeline/documents/:id">
            <PrivateLayout>
              <LoanDocs />
            </PrivateLayout>
          </Route>
          <Route path="/pipeline/important-dates/:id">
            <PrivateLayout>
              <ImportantDates />
            </PrivateLayout>
          </Route>
          <Route path="/pipeline/loan-summary/:id">
            <PrivateLayout>
              <LoanSummary />
            </PrivateLayout>
          </Route>
          {!LoginState.limited && (
            <Route path="/pipeline/rate-lock/:id">
              <PrivateLayout>
                <RateLock />
              </PrivateLayout>
            </Route>
          )}
          <Route path="/pipeline/submission/:id">
            <PrivateLayout>
              <Submission />
            </PrivateLayout>
          </Route>

          {/* RESOURCES */}
          <Route path="/resources" exact>
            <PrivateLayout>
              <Resources />
            </PrivateLayout>
          </Route>
          <Route path="/resources/forms" exact>
            <PrivateLayout>
              <Forms />
            </PrivateLayout>
          </Route>
          <Route path="/resources/geoid-validator" exact>
            <PrivateLayout>
              <GeocodeFinder />
            </PrivateLayout>
          </Route>
          <Route path="/resources/product-matrix" exact>
            <PrivateLayout>
              <ProductMatrix />
            </PrivateLayout>
          </Route>
          {!LoginState.limited && (
            <Route path="/resources/daily-rates" exact>
              <PrivateLayout>
                <DailyRates />
              </PrivateLayout>
            </Route>
          )}
          {!LoginState.limited && (
            <Route path="/resources/pricing">
              <PrivateLayout>
                <Pricing />
              </PrivateLayout>
            </Route>
          )}

          {/* TOOLS */}
          <Route path="/tools/appraisal-finder">
            <PrivateLayout>
              <AppraisalFinder />
            </PrivateLayout>
          </Route>

          {/* OTHERS */}
          <Route path="/associated-email/:id">
            <PrivateLayout>
              <AssociatedEmail />
            </PrivateLayout>
          </Route>
          <Route path="/dashboard" exact>
            <PrivateLayout>
              <Dashboard />
            </PrivateLayout>
          </Route>
          <Route path="/details/:id" exact>
            <PrivateLayout>
              <FileDetails />
            </PrivateLayout>
          </Route>
          <Route path="/email-search" exact>
            <PrivateLayout>
              <EmailSearch />
            </PrivateLayout>
          </Route>
          <Route path="/help" exact>
            <PrivateLayout>
              <Help />
            </PrivateLayout>
          </Route>
          <Route path="/lenderpanel/:tab" exact>
            <PrivateLayout>
              <LenderPanel />
            </PrivateLayout>
          </Route>
          <Route path="/messages" exact>
            <PrivateLayout>
              <Messages />
            </PrivateLayout>
          </Route>
          <Route path="/pdf">
            <PrivateLayout>
              <UploadPdf />
            </PrivateLayout>
          </Route>
          <Route path="/redirect/:id" exact>
            <PrivateLayout>
              <PageRedirect />
            </PrivateLayout>
          </Route>
          <Route path="/restricted">
            <PrivateLayout>
              <Restricted />
            </PrivateLayout>
          </Route>
          <Route path="/settings/:tab" exact>
            <PrivateLayout>
              <Settings />
            </PrivateLayout>
          </Route>
          <Route path="/status-board/:tab" exact>
            <PrivateLayout>
              <StatusBoard />
            </PrivateLayout>
          </Route>
          <Route path="/wizardsubmission/:id" exact>
            <PrivateLayout>
              <WizardSubmission />
            </PrivateLayout>
          </Route>
          <Route path="/wizard1003" exact>
            <PrivateLayout>
              <Wizard1003 />
            </PrivateLayout>
          </Route>
          <Route path="/admin" exact>
            <PrivateLayout>
              <Admin />
            </PrivateLayout>
          </Route>
          <Route path="/adminmessage" exact>
            <PrivateLayout>
              <AdminMessage />
            </PrivateLayout>
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};
export default App;
