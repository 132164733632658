import React from 'react';
import Login from '../Login';
import { useInView, animated, useSpring } from '@react-spring/web';
import { Link } from 'react-router-dom';

const Landing = () => {
  const [rotateProps, api] = useSpring(
    () => ({
      from: { rotate: 0 },
      to: { rotate: 360 },
      loop: true,
      config: { duration: 10000 },
    }),
    []
  );

  const animation1 = () => ({
    from: {
      opacity: 0,
      x: -100,
    },
    to: {
      opacity: 1,
      x: 0,
      config: { tension: 280, friction: 60 },
    },
  });
  const animation2 = () => ({
    from: {
      opacity: '0%',
    },
    to: {
      opacity: '100%',
    },
  });
  const config1 = {
    // once: true,
    rootMargin: '-20% 0% -50% 0%',
  };

  const config2 = {
    // once: true,
    rootMargin: '-10% 0% -50% 0%',
  };

  const [ref1, springs1] = useInView(animation1, config1);
  const [ref2, springs2] = useInView(animation1, config1);
  const [ref3, springs3] = useInView(animation1, config1);
  const [ref4, springs4] = useInView(animation2, config2);
  const [ref5, springs5] = useInView(animation2, config2);

  return (
    <div className="snap-parent">
      <div className="container snap-child d-flex flex-column-reverse flex-lg-row justify-content-center align-items-center pattern-2 gap-5 min-vh-100">
        <div className="flex-4 mx-2 mx-md-0 mb-5 mb-md-0">
          <h1 className="display-3 fw-bold">
            We have done our research.
            <br />
            We have lending down to a science.
          </h1>
          <h5 className="mt-1 text-black-50 lh-base fs-3">
            We know what it takes to be a good lending partner.
            <br />
            Let us show you how it's done.
          </h5>
        </div>
        <div className="card border-0 rounded-2 px-3 px-sm-5 py-5 my-5 my-lg-0 shadow-lg flex-1">
          <img
            src="/images/MTG_Logo.png"
            alt="MTG logotype"
            style={{ width: '100%', maxWidth: '300px' }}
            className="mb-4"
          />
          <Login />
        </div>
      </div>
      <div
        id="content-1"
        className="container-fluid bg-warning bg-gradient py-5 snap-child d-flex align-items-center border-top border-bottom"
      >
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="d-flex flex-column flex-md-row gap-4 gap-md-5 align-items-center text-center text-md-left">
              <div>
                <h2 className="display-4 fw-bold">
                  Check out our program matrix
                </h2>
                <h4 className="text-muted">
                  Take a look at our programs and find out which one is best for
                  you
                </h4>
              </div>
              <animated.div style={{ ...rotateProps, width: 'fit-content' }}>
                <Link to="/compare-programs">
                  <img
                    src="/images/seal-link.png"
                    alt="Program Matrix Link"
                    width="150px"
                  />
                </Link>
              </animated.div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="content-1"
        className="container-fluid snap-child d-flex justify-content-center align-items-center pattern-1 min-vh-100"
      >
        <div className="p-2 p-md-0">
          <div className="background"></div>
          <animated.div ref={ref1} style={springs1}>
            <div className="d-flex mb-3 mb-md-5" id="item1">
              <span className="text-primary fs-2 me-2 mt-4">1.</span>
              <div>
                <h1 className="display-3 fw-bold text-dark">
                  No more empty promises.
                </h1>
                <h5 className="mt-1 text-black-50 lh-base">
                  We will do everything humanly possible to keep our word.{' '}
                  <br />
                  We will be clear on our commitments and will always deliver.
                </h5>
              </div>
            </div>
          </animated.div>
          <animated.div ref={ref2} style={springs2}>
            <div className="d-flex mb-3 mb-md-5">
              <span className="text-primary fs-2 me-2 mt-4">2.</span>
              <div>
                <h1 className="display-3 fw-bold text-dark">
                  We know the frustrations.
                </h1>
                <h5 className="mt-1 text-black-50 lh-base">
                  We know the frustrations. <br />
                  We won’t stop until we find ways to ease your pain.
                </h5>
              </div>
            </div>
          </animated.div>
          <animated.div ref={ref3} style={springs3}>
            <div className="d-flex">
              <span className="text-primary fs-2 me-2 mt-4">3.</span>
              <div>
                <h1 className="display-3 fw-bold text-dark">
                  No more being left in the dark.
                </h1>
                <h5 className="mt-1 text-black-50 lh-base">
                  We believe communication is the key to a good partnership.{' '}
                  <br />
                  We will always be within reach
                </h5>
              </div>
            </div>
          </animated.div>
        </div>
      </div>
      <div className="container-fluid snap-child d-flex justify-content-center align-items-center min-vh-100">
        <div
          className="d-flex flex-column align-items-start p-2 p-md-0"
          id="content-2"
        >
          <div className="position-relative">
            <animated.div ref={ref4} style={springs4}>
              <div
                className="bg-dark position-absolute top-50 w-100"
                style={{ height: '2px' }}
              ></div>
            </animated.div>
            <h3 className="fs-2 text-muted">Others make statements.</h3>
          </div>
          <h1 className="display-3 fw-bold mb-5">
            <span className="text-primary fw-bold">We deliver</span> on
            promises.
          </h1>
          <div className="position-relative">
            <animated.div ref={ref5} style={springs5}>
              <div
                className="bg-dark position-absolute top-50 w-100"
                style={{ height: '2px' }}
              ></div>
            </animated.div>
            <h3 className="fs-2 text-muted">Making a statement is easy.</h3>
          </div>
          <h1 className="display-3 fw-bold ">
            Following through is{' '}
            <span className="text-primary fw-bold">what matters.</span>
          </h1>
        </div>
      </div>
      <footer>
        <div className="container-fluid py-5">
          <div className="container my-5">
            <div className="vstack gap-3">
              <div className="d-flex flex-column flex-lg-row gap-4 mb-3">
                <div>
                  <i className="bi bi-envelope text-white" />
                  <a
                    className="ms-2 text-white"
                    href="mailto:info@mtgrl.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@mtgrl.com
                  </a>
                </div>
                <div>
                  <i className="bi bi-telephone text-white" />
                  <a className="ms-2 text-white" href="tel:1-800-562-3220">
                    +1 (833) 684 5227
                  </a>
                </div>
                <div>
                  <i className="bi geo-alt text-white" />
                  <a
                    className="ms-2 text-white"
                    href="https://goo.gl/maps/jWTvCK1bpuuKAfRR9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    3435 Wilshire Blvd Ste 2880 Los Angeles, CA 90010
                  </a>
                </div>
              </div>
              <p className="text-white">
                &copy; {new Date().getFullYear()} Mortgage Research Laboratory Inc. All Right
                Reserved. Mortgage Research Laboratory Inc. is a direct lender.
                <br />
                Loans made or arranged pursuant to a California Finance Lenders
                Law License. Not available in all states. <br />
                Equal Housing Lender.
              </p>

              <span className="text-white">
                NMLS ID #1944774. For licensing information, go to:&nbsp;
                <a
                  className="text-light"
                  href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1944774"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.nmlsconsumeraccess.org
                </a>
              </span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Landing;
