import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LoginState from './LoginState';

const ModalCard = styled.div`
  width: 100%;
  background: linear-gradient(to right, tomato 66%, dodgerblue);
  text-align: center;
  font-size: 1.2em;
  color: white;
`;
const Message = styled.span`
  display: ${props => (props.active ? 'inline-block' : 'none')};
  animation: ${props => (props.active ? 'fadeIn 1s' : 'none')};
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export default class GlobalMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      messages: [],
      active: 0,
    };
  }

  componentDidMount() {
    this.getLatestMessage();
    this.interval = setInterval(this.getLatestMessage.bind(this), 300000); // 300000 ms = 5 mins
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.messages.length > 1 && !this.messageInterval) {
      this.messageInterval = setInterval(this.changeMessage, 7000); // 7000 ms = 7 seconds
    } else if (this.state.messages.length <= 1 && this.messageInterval) {
      clearInterval(this.messageInterval);
      this.messageInterval = null;
    }
  }

  changeMessage = () => {
    const { active, messages } = this.state;
    if (active === messages.length - 1) {
      this.setState({ active: 0 });
    } else {
      this.setState(prevState => ({ active: prevState.active + 1 }));
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async getLatestMessage() {
    // skip if logged out
    if (!LoginState.loggedIn || !LoginState.userName) return;

    const response = axios.get('/getLatestMessage', {
      params: { pUserName: LoginState.userName },
    });

    // the response.data will contain the values returned
    let resultData = (await response).data;

    this.setState({
      messages: resultData,
      show: resultData.length > 0,
    });
  }

  render() {
    const { active, messages } = this.state;

    return (
      messages.length > 0 && (
        <ModalCard className="py-1">
          {messages.map(({ message }, index) => (
            <Message active={active === index} key={message}>
              {message}
            </Message>
          ))}
        </ModalCard>
      )
    );
  }
}
