import React, { Component } from 'react';
import axios from 'axios';
import Adjustments from '../components/Adjustments';
import LoginState from '../components/LoginState';
import ProgramList from '../components/ProgramList';
import ProgramRates from '../components/ProgramRates';
import Toast from '../components/Toast';
import { PRICING_OPTIONS } from '../helpers/constants';
import {
  getPropertyType,
  formatPrice,
  unformatCurrency,
  formatCurrency2,
} from '../helpers/utils';
import Modal from './Modal';
import ModalWait from './ModalWait';
import Tooltip from './Tooltip';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

class RateLock extends Component {
  constructor(props) {
    super(props);
    this.programsRef = React.createRef();
    this.state = {
      loan: {},
      lockPeriod: 2,
      compPlan: 1,
      lockTime: '',
      selectedTime: '',
      noProgramReason: '',
      selectedRate: '',
      successMessage: '',
      rateAdjustments: [],
      searchLog: [],
      showConfirmModal: false,
      showCicModal: false,
      rateClicked: false,
      lockWindowClosed: false,
      todayRateSheet: false,
    };
  }

  async GetSearchLog(fileID) {
    // now update server with this data
    const response = axios.get('/getUpdatedSearchLog', {
      params: {
        pFileID: fileID,
        pUserID: LoginState.userId,
      },
    });
    var resultData = (await response).data;
    this.setState({ searchLog: resultData });
  }

  PageLoad(fileID) {
    this.setState({
      loan: {},
      programList: [],
      allPrograms: [],
      noProgramReason: '',
    });
    this.GetSearchLog(fileID);
    this.CheckRateSuspended();
    this.GetSummaryData(fileID);
  }

  componentDidMount() {
    this.checkRateTimer();
    this.PageLoad(this.props.match.params.id);
    this.interval = setInterval(() => this.checkRateTimer(), 60000); // check every minute
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async checkRateTimer() {
    var dtNow = new Date().toLocaleTimeString('en-US', {
      timeZone: 'America/Los_Angeles',
    });
    if (
      Number(dtNow.split(':')[0]) >= 4 &&
      Number(dtNow.split(':')[0]) !== 12 &&
      dtNow.endsWith('PM') &&
      !LoginState.userRole.toLowerCase().includes('lock')
    ) {
      this.setState({ lockWindowClosed: true });
    } else if (!this.state.todayRateSheet) {
      const response = axios.get('/GetDailyRates');
      try {
        var resultData = (await response).data;
        if (resultData && resultData.length > 0) {
          var dStr = new Date().toLocaleDateString('en-US', {
            timeZone: 'America/Los_Angeles',
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
          });
          if (dStr === resultData[0].time.split(' ')[0]) {
            this.setState({ lockWindowClosed: false, todayRateSheet: true });
          } else {
            this.setState({ lockWindowClosed: true, todayRateSheet: false });
          }
        } else {
          this.setState({ lockWindowClosed: true });
        }
      } catch {
        this.setState({ lockWindowClosed: true });
      }
    }
  }

  // get data before page loads
  async GetSummaryData(fileID) {
    const response = axios.get('/getratelockdata', {
      params: { pFileID: fileID },
    });

    // the response.data will contain the values returned
    try {
      var resultData = (await response).data;
      this.setState({
        loan: resultData,
        selectedRate: resultData.intRate?.replace('%', ''),
        lockPeriod: resultData.lockDays?.includes('15') ? 1 : 2,
      });
      if (resultData)
        this.UpdateProgramData(
          resultData.lockDays?.includes('15') ? 1 : 2,
          resultData
        );
    } catch {
      console.log('Error fetching loan details');
    }
  }

  async CheckRateSuspended() {
    axios.get('/IsRateSuspended').then(res => {
      if (res && res.data) {
        var statelist = { rateSuspend: res.data === 'False' ? null : res.data };
        if (this.state.showConfirmModal && res.data !== 'False')
          statelist['showConfirmModal'] = false;
        if (this.state.showCicModal && res.data !== 'False')
          statelist['showCicModal'] = false;
        this.setState(statelist);
      }
    });
  }

  EscrowType(value) {
    switch (value) {
      case 'No Impound':
        return 1;
      case 'Tax and Insurance':
        return 2;
      case 'Tax':
        return 3;
      case 'Insurance':
        return 4;
      default:
        return 0;
    }
  }

  async UpdateProgramData(lockPeriodInput, loan) {
    if (JSON.stringify(loan) === '{}') return;
    var propType = '';
    var unitCount = parseInt(loan?.numberOfUnits);
    if (unitCount > 1) {
      propType = unitCount + ' Unit';
    } else {
      propType = getPropertyType(loan?.propertyType);
    }

    const res = await axios.get('/program', {
      params: {
        pTimestamp: this.state.selectedTime
          ? moment(this.state.selectedTime).format('MM/DD/YY HH:mm')
          : loan?.dataTime,
        pProgCode: loan?.programCode, // specific program code
        pProgFamily:
          loan?.rateEvents.length > 0 ? loan?.programCode.substr(0, 5) : '',
        pDocumentType: loan?.programCode.toString().includes('MTGP') ? 0 : 1, // portfolio, full doc
        pLoanType:
          loan?.programCode.toString().includes('A') &&
          !loan?.programCode.toString().includes('AF')
            ? 2
            : 1, // fixed, adjustable
        pLoanTerm: '',
        pCounty: loan?.county,
        pState: loan?.state,

        pFirstLoanAmt: parseInt(loan?.firstLoan.replace(/\$|,/g, '')), // loan amount
        pSecondLoanAmt: 0, // loan amount
        pLoanPurpose:
          loan?.loanPurpose === 'PUR'
            ? '1'
            : loan?.loanPurpose === 'R/T'
            ? '2'
            : '3', // PUR, R/T, C/O
        pPropertyType: propType,
        pOccupancyType: parseInt(loan?.occupancyType), // primary, second home, investment

        pLtv: loan?.ltv,
        pCltv: loan?.cltv,
        pFico: loan?.creditScore,
        pDtiFront: loan?.dti.split('/')[0].replace('%', ''),
        pDtiBack: loan?.dti.split('/')[1].replace('%', ''),
        pLockPeriod: loan.programCode.includes('MTGP')
          ? 2
          : parseInt(lockPeriodInput ? lockPeriodInput : this.state.lockPeriod), // 15 Day, 30 Day
        pPmi: this.state.pmi, // No PMI, Borrower Paid, Lender Paid
        pIncomeVerification: loan?.incomeVerification,
        pEscrow: this.EscrowType(loan?.escrow),
        pForeignNational: loan?.foreignNational === '1' ? 1 : 2, // 1 - Yes, 2 - No
        pMonthsReserves: 1, // 1 - 2 months, 2 - 6 months, 3 - 12 months
        pPrepayPenalty:
          loan?.prepayPenaltyTerm === '---'
            ? 1
            : Number(loan.prepayPenaltyTerm) / 12 + 1, // 1 - No Prepay Penalty, 2 - 1 Year, 3 - 2 Year
        pMoreThan4Units: loan?.moreThan4Units,
        pMissedPayments: loan?.missedPayments,
      },
    });

    if (!res || res.error) {
      this.setState({
        noProgramReason: 'Server Error While Fetching Programs!',
      });
    } else {
      const programs = res.data;
      var availablePrograms = [];
      var issues = [];
      for (var i = 0; i < programs.length; i++) {
        if (programs[i].issues.length === 0)
          availablePrograms.push(programs[i]);
        else {
          for (var j = 0; j < programs[i].issues.length; j++) {
            var dupIssue = false;
            for (var k = 0; k < issues.length; k++) {
              if (
                programs[i].issues[j].split('(')[0] === issues[k].split('(')[0]
              ) {
                dupIssue = true;
                break;
              }
            }
            if (!dupIssue) issues.push(programs[i].issues[j]);
          }
        }
      }
      var selectedIndex = 0;
      var issueStr = '';
      if (availablePrograms.length === 0) {
        for (var l = 0; l < issues.length; l++) {
          issueStr = issueStr + issues[l] + '\n';
        }
      } else if (loan?.programCode) {
        for (var m = 0; m < availablePrograms.length; m++) {
          if (availablePrograms[m].code === loan.programCode) {
            selectedIndex = m;
            break;
          }
        }
      }
      this.setState(
        {
          noProgramReason: issueStr,
          programList: availablePrograms,
          allPrograms: programs,
          selectedProgram: availablePrograms[selectedIndex],
          /* ...programs */
        },
        () =>
          this.programsRef.current &&
          window.scrollTo(0, this.programsRef.current.offsetTop)
      );
    }
  }

  GetPropertyType() {
    var unitCount = parseInt(this.state.loan?.numberOfUnits);
    if (unitCount > 1) {
      return unitCount + ' Unit';
    }
    return getPropertyType(this.state.loan.propertyType);
  }

  GetOccupancyType() {
    try {
      return PRICING_OPTIONS.occupancyType[
        parseInt(this.state.loan?.occupancyType) - 1
      ].label;
    } catch {
      return '---';
    }
  }

  captureInput = e => {
    if (this.state.errors) {
      let errors = { ...this.state.errors };
      delete errors[e.target.name];
      this.setState({
        [e.target.name]: e.target.value,
        errors,
      });
    } else {
      if (e.target.name === 'lockPeriod') {
        this.state.loan.lockPeriod = e.target.value;
        this.UpdateProgramData(e.target.value, this.state.loan);
      }
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleChange = (rate, price, total, credit) => {
    this.setState({
      selectedRate: rate,
      selectedBase: price,
      selectedPrice: total,
      selectedCredit: credit,
      rateClicked: true,
    });
  };

  RequestRateLock() {
    this.CheckRateSuspended();
    this.setState({ showConfirmModal: true });
  }

  RequestCIC() {
    this.CheckRateSuspended();
    this.setState({ showCicModal: true });
  }

  ShowCicModal() {
    const { selectedRate, selectedProgram, loan } = this.state;

    if (!selectedProgram || !loan.rateEvents || loan.rateEvents.length === 0) {
      return <React.Fragment />;
    }

    var totalRateAdj = 0;
    if (selectedProgram.rateAdjustments) {
      for (var i = 0; i < selectedProgram.rateAdjustments.length; i++) {
        totalRateAdj += selectedProgram.rateAdjustments[i].amount;
      }
    }

    return (
      <table>
        <tbody>
          {loan.programCode !== selectedProgram.code && (
            <tr>
              <td width="140px">Loan Program:</td>
              <td width="110px">{loan.programCode}</td>
              <td width="60px">
                <i class="bi bi-arrow-right-short"></i>
              </td>
              <td width="110px">{selectedProgram.code}</td>
            </tr>
          )}

          {loan.rateEvents[0].finalRate !== selectedRate && (
            <tr>
              <td width="140px">Rate:</td>
              <td width="110px">{loan.rateEvents[0].finalRate} %</td>
              <td width="60px">
                <i class="bi bi-arrow-right-short"></i>
              </td>
              <td width="110px">
                {selectedRate} %
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  ShowConfirmModal() {
    const {
      selectedRate,
      selectedBase,
      selectedPrice,
      selectedCredit,
      selectedProgram,
      lockPeriod,
    } = this.state;

    if (
      !selectedProgram ||
      !selectedRate ||
      selectedBase === null ||
      selectedPrice === null ||
      !selectedCredit
    ) {
      return <React.Fragment></React.Fragment>;
    }
    var i;
    var totalRateAdj = 0;
    var totalPriceAdj = 0;
    for (i = 0; i < selectedProgram.rateAdjustments.length; i++) {
      totalRateAdj += selectedProgram.rateAdjustments[i].amount;
    }
    for (i = 0; i < selectedProgram.priceAdjustments.length; i++) {
      totalPriceAdj += selectedProgram.priceAdjustments[i].amount;
    }

    var baseNum = Number(selectedBase);
    var priceNum = Number(selectedPrice);

    return (
      <table>
        <tbody>
          <tr>
            <td width="200px">Loan Program:</td>
            <td style={{ textAlign: 'center' }}>{selectedProgram.code}</td>
          </tr>

          <tr>
            <td colSpan={3}>
              <hr />
            </td>
          </tr>

          <tr>
            <td>Base Rate:</td>
            <td style={{ textAlign: 'center' }}>
              {(selectedRate - totalRateAdj).toFixed(3)} %
            </td>
          </tr>
          <tr>
            <td>LLRAs:</td>
            <td style={{ textAlign: 'center' }}>{totalRateAdj.toFixed(3)} %</td>
          </tr>
          <tr>
            <td style={{ fontWeight: 'bold', color: 'red' }}>Total Rate:</td>
            <td
              style={{ fontWeight: 'bold', color: 'red', textAlign: 'center' }}
            >
              {selectedRate} %
            </td>
            <td />
          </tr>

          <tr>
            <td colSpan={3}>
              <hr />
            </td>
          </tr>

          <tr>
            <td>Base Price:</td>
            <td style={{ textAlign: 'center' }}>{formatPrice(baseNum)}</td>
          </tr>
          <tr>
            <td>LLPAs:</td>
            <td style={{ textAlign: 'center' }}>
              {formatPrice(totalPriceAdj)}
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: 'bold', color: 'red' }}>Final Price:</td>
            <td
              style={{ fontWeight: 'bold', color: 'red', textAlign: 'center' }}
            >
              {formatPrice(priceNum)}
            </td>
          </tr>

          <tr>
            <td colSpan={3}>
              <hr />
            </td>
          </tr>

          <tr>
            <td
              style={{
                fontWeight: 'bold',
                color: selectedCredit[0] === '-' ? 'red' : 'green',
              }}
            >
              Credit:
            </td>
            <td
              style={{
                fontWeight: 'bold',
                color: selectedCredit[0] === '-' ? 'red' : 'green',
                textAlign: 'center',
              }}
            >
              {selectedCredit}
            </td>
          </tr>

          <tr>
            <td colSpan={3}>
              <hr />
            </td>
          </tr>

          <tr>
            <td>Lock Days:</td>
            <td style={{ textAlign: 'center' }}>
              {PRICING_OPTIONS.lockPeriod[lockPeriod - 1].label}
            </td>
          </tr>
          <tr>
            <td>Escrow:</td>
            <td style={{ textAlign: 'center' }}>{this.state.loan.escrow}</td>
          </tr>

          {this.state.selectedTime && (
            <tr>
              <td
                colSpan={2}
                style={{ color: 'blue', fontSize: '14px', paddingTop: '20px' }}
              >
                Note: Submission Time ={' '}
                <span style={{ fontWeight: 'bold', color: 'blue' }}>
                  {this.displayTime(this.state.selectedTime)}
                </span>
                <br />
                <span style={{ fontSize: '12px' }}>
                  (Emails Will Not Be Sent Out)
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  async SendCicRequest() {
    const { selectedRate, selectedProgram, loan } = this.state;
    var i;
    var totalRateAdj = 0;
    var rateAdjs = '';

    for (i = 0; i < selectedProgram.rateAdjustments.length; i++) {
      totalRateAdj += selectedProgram.rateAdjustments[i].amount;
      rateAdjs +=
        selectedProgram.rateAdjustments[i].reason +
        '$$$$$' +
        selectedProgram.rateAdjustments[i].amount +
        '$$$$$';
    }

    // clear first
    this.setState({ showCicModal: false, isUploading: true });

    var changesToNotify = [];
    if (loan.programCode !== selectedProgram.code) {
      changesToNotify.push('Loan Program');
      changesToNotify.push(loan.programCode);
      changesToNotify.push(selectedProgram.code);
      changesToNotify.push('Eligibility');
    }
    if (
      loan.rateEvents[0].baseRate !== (selectedRate - totalRateAdj).toFixed(3)
    ) {
      changesToNotify.push('Rate');
      changesToNotify.push(loan.rateEvents[0].finalRate);
      changesToNotify.push(selectedRate);
      changesToNotify.push('Eligibility');
    }

    var response = axios.get('/sendcocnotifications', {
      params: {
        pFileID: this.props.match.params.id,
        pChanges: changesToNotify.join('$$$$$'),
        pUserName: LoginState.userName,
      },
    });

    try {
      var resultData = (await response).data;
      if (resultData) {
        this.setState({
          successMessage: 'CIC Request Submitted!',
          showCicModal: false,
          isUploading: false,
        });
      }
    } catch {
      this.setState({
        showCicModal: false,
        isUploading: false,
      });
    }
  }

  async SendChangeNotifications() {
    this.setState({ showConfirmModal: false, isUploading: true });
    const {
      selectedRate,
      selectedBase,
      selectedPrice,
      selectedCredit,
      selectedProgram,
      lockPeriod,
    } = this.state;

    var i;
    var totalRateAdj = 0;
    var totalPriceAdj = 0;
    var rateAdjs = '';
    var priceAdjs = '';

    for (i = 0; i < selectedProgram.rateAdjustments.length; i++) {
      totalRateAdj += selectedProgram.rateAdjustments[i].amount;
      rateAdjs +=
        selectedProgram.rateAdjustments[i].reason +
        '$$$$$' +
        selectedProgram.rateAdjustments[i].amount +
        '$$$$$';
    }
    for (i = 0; i < selectedProgram.priceAdjustments.length; i++) {
      totalPriceAdj += selectedProgram.priceAdjustments[i].amount;
      priceAdjs +=
        selectedProgram.priceAdjustments[i].reason +
        '$$$$$' +
        selectedProgram.priceAdjustments[i].amount +
        '$$$$$';
    }

    axios
      .get('/getratelock', {
        params: {
          pFileID: this.props.match.params.id,
          pUserName: LoginState.userName,
          pLockPeriod: lockPeriod,
          pBaseRate: (selectedRate - totalRateAdj).toFixed(3),
          pLLRAs: totalRateAdj.toFixed(3),
          pFinalRate: selectedRate,
          pBasePrice: selectedBase,
          pLLPAs: totalPriceAdj,
          pFinalPrice: selectedPrice,
          pCredit: selectedCredit,
          pRateAdjustments: rateAdjs,
          pPriceAdjustments: priceAdjs,
          pProgCode: selectedProgram.code,
          pOriginalRate: this.state.loan.intRate,
          pTimestamp: this.state.selectedTime
            ? this.displayTime(this.state.selectedTime)
            : '',
        },
      })
      .then(res => {
        if (res) {
          this.setState({
            showConfirmModal: false,
            isUploading: false,
            successMessage: 'Rate Lock Request Submitted',
            loan: {},
          });
          this.GetSummaryData(this.props.match.params.id);
        }
      })
      .catch(e => console.log(e, 'Error submitting rate lock request'));
  }

  GetRateLockRow(index, rateEvents) {
    var i;
    var rateAdj = '';
    var priceAdj = '';

    if (rateEvents[index].rateAdjustments) {
      var splitRate = rateEvents[index].rateAdjustments.split('$$$$$');
      for (i = 0; i < splitRate.length - 1; i += 2) {
        rateAdj +=
          splitRate[i] + ' - ' + formatPrice(Number(splitRate[i + 1])) + '\n';
      }
    }

    if (rateEvents[index].priceAdjustments) {
      var splitPrice = rateEvents[index].priceAdjustments.split('$$$$$');
      for (i = 0; i < splitPrice.length - 1; i += 2) {
        priceAdj +=
          splitPrice[i] + ' - ' + formatPrice(Number(splitPrice[i + 1])) + '\n';
      }
    }

    if(rateEvents[index].description.includes('Rate Lock Cancelled')) {
      return (
        <tr key={rateEvents[index].timestamp}>
          <td colSpan={12} style={{background:'#ffdee3', textAlign:'center'}}>Rate Lock Cancelled (<span style={{color:'red'}}>{rateEvents[index].timestamp.substr(0,8)}</span>)</td>
        </tr>
      );
    }

    if (
      index === rateEvents.length - 1 ||
      rateEvents[index].description.includes('Loan Program') ||
      rateEvents[index].baseRate !== rateEvents[index + 1].baseRate ||
      rateEvents[index].llras !== rateEvents[index + 1].llras ||
      rateEvents[index].finalRate !== rateEvents[index + 1].finalRate ||
      rateEvents[index].basePrice !== rateEvents[index + 1].basePrice ||
      rateEvents[index].llpas !== rateEvents[index + 1].llpas ||
      rateEvents[index].priceAdjustments !== rateEvents[index + 1].priceAdjustments ||
      rateEvents[index].adjPrice !== rateEvents[index + 1].adjPrice ||
      rateEvents[index].lockDays !== rateEvents[index + 1].lockDays ||
      rateEvents[index].lockExtFee !== rateEvents[index + 1].lockExtFee ||
      rateEvents[index].finalPrice !== rateEvents[index + 1].finalPrice ||
      rateEvents[index].credit !== rateEvents[index + 1].credit
    ) {
      return (
        <tr key={rateEvents[index].timestamp}>
          <td style={{ textAlign: 'center' }}>{rateEvents[index].timestamp}</td>
          <td style={{ textAlign: 'center' }}>
            {rateEvents[index].programCode}
          </td>
          <td
            style={{
              textAlign: 'center',
              background:
                index < rateEvents.length - 1 &&
                rateEvents[index].baseRate !== rateEvents[index + 1].baseRate
                  ? '#fffbab'
                  : '',
            }}
          >
            {rateEvents[index].baseRate} %
          </td>
          <td
            style={{
              textAlign: 'center',
              background:
                index < rateEvents.length - 1 &&
                rateEvents[index].llras !== rateEvents[index + 1].llras
                  ? '#fffbab'
                  : '',
            }}
          >
            {!rateAdj ? (
              '-'
            ) : (
              <div style={{ color: '#49a0de' }}>
                <Tooltip tip={rateAdj}>
                  {formatPrice(Number(rateEvents[index].llras))} %
                </Tooltip>
              </div>
            )}
          </td>
          <td
            style={{
              textAlign: 'center',
              background:
                index < rateEvents.length - 1 &&
                rateEvents[index].finalRate !== rateEvents[index + 1].finalRate
                  ? '#fffbab'
                  : '',
            }}
          >
            {rateEvents[index].finalRate} %
          </td>
          <td
            style={{
              textAlign: 'center',
              background:
                index < rateEvents.length - 1 &&
                rateEvents[index].basePrice !== rateEvents[index + 1].basePrice
                  ? '#fffbab'
                  : '',
            }}
          >
            {formatPrice(Number(rateEvents[index].basePrice))}
          </td>
          <td
            style={{
              textAlign: 'center',
              background:
                index < rateEvents.length - 1 &&
                (rateEvents[index].llpas !== rateEvents[index + 1].llpas ||
                 rateEvents[index].priceAdjustments !== rateEvents[index + 1].priceAdjustments)
                  ? '#fffbab'
                  : '',
            }}
          >
            {!priceAdj ? (
              '-'
            ) : (
              <div style={{ color: '#49a0de' }}>
                <Tooltip tip={priceAdj}>
                  {formatPrice(Number(rateEvents[index].llpas))}
                </Tooltip>
              </div>
            )}
          </td>
          <td
            style={{
              textAlign: 'center',
              background:
                index < rateEvents.length - 1 &&
                rateEvents[index].adjPrice !== rateEvents[index + 1].adjPrice
                  ? '#fffbab'
                  : '',
            }}
          >
            {formatPrice(Number(rateEvents[index].adjPrice))}
          </td>
          <td
            style={{
              textAlign: 'center',
              background:
                index < rateEvents.length - 1 &&
                rateEvents[index].lockDays !== rateEvents[index + 1].lockDays
                  ? '#fffbab'
                  : '',
            }}
          >
            {rateEvents[index].lockDays}
          </td>
          <td
            style={{
              textAlign: 'center',
              background:
                index < rateEvents.length - 1 &&
                rateEvents[index].lockExtFee !==
                  rateEvents[index + 1].lockExtFee
                  ? '#fffbab'
                  : '',
            }}
          >
            {formatPrice(Number(rateEvents[index].lockExtFee))}
          </td>
          <td
            style={{
              textAlign: 'center',
              background:
                index < rateEvents.length - 1 &&
                rateEvents[index].finalPrice !==
                  rateEvents[index + 1].finalPrice
                  ? '#fffbab'
                  : '',
            }}
          >
            {Number(rateEvents[index].finalPrice) >
            Number(rateEvents[index].adjPrice) +
              Number(rateEvents[index].lockExtFee) ? (
              <div style={{ color: '#49a0de' }}>
                <Tooltip tip={'Final Price Limited By Rate Cap'}>
                  {formatPrice(Number(rateEvents[index].finalPrice))}
                </Tooltip>
              </div>
            ) : (
              formatPrice(Number(rateEvents[index].finalPrice))
            )}
          </td>
          <td
            style={{
              textAlign: 'center',
              color: rateEvents[index].credit.startsWith('-') ? 'red' : 'green',
              background:
                index < rateEvents.length - 1 &&
                rateEvents[index].credit !== rateEvents[index + 1].credit
                  ? '#fffbab'
                  : '',
            }}
          >
            {rateEvents[index].credit}
          </td>
        </tr>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }

  DisplayRateLockData(rateEvents) {
    if (!rateEvents) return <React.Fragment></React.Fragment>;

    var rows = [];
    for (var i = 0; i < rateEvents.length; i++) {
      rows.push(this.GetRateLockRow(i, rateEvents));
    }
    return <React.Fragment>{rows.map(row => row)}</React.Fragment>;
  }

  delay(milliseconds) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  async UpdateTime() {
    this.setState({
      loan: {},
      programList: [],
      noProgramReason: '',
      selectedTime: this.state.lockTime,
    });
    await this.delay(500);
    this.GetSummaryData(this.props.match.params.id);
  }

  displayTime(timeStr) {
    var dispStr = moment(timeStr).format('MM/DD/YY HH:mm');
    var splitStr = dispStr.split(' ');
    var timeSplit = splitStr[1].split(':');
    var pm = Number(timeSplit[0]) >= 12;
    var hour =
      Number(timeSplit[0]) === 0
        ? 12
        : Number(timeSplit[0]) > 12
        ? Number(timeSplit[0]) - 12
        : timeSplit[0];
    return (
      splitStr[0] + ' ' + hour + ':' + timeSplit[1] + ' ' + (pm ? 'PM' : 'AM')
    );
  }

  render() {
    const { loan, errors, selectedProgram, selectedRate } = this.state;
    const defaultAddress = `${loan.street}\n${loan.city}, ${loan.state} ${loan.zip}`;

    var totalRateAdj = 0;
    if (selectedProgram && selectedProgram.rateAdjustments) {
      for (var i = 0; i < selectedProgram.rateAdjustments.length; i++) {
        totalRateAdj += selectedProgram.rateAdjustments[i].amount;
      }
    }

    if (!this.state.loan?.borrower) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  marginTop: '50px',
                }}
              >
                <img
                  src="/images/spinner.gif"
                  alt=""
                  height="64px"
                  width="64px"
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="h4 text-uppercase">
              {this.state.loan?.lockRequested
                ? this.state.loan.rateLockDate !== '---'
                  ? 'rate locked'
                  : 'rate lock requested'
                : 'request rate lock'}
              <button
                className="btn btn-text dropdown-toggle"
                style={{ marginLeft: '5px' }}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-list"></i>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <p className="dropdown-item mb-0 disabled">Recent Files</p>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {this.state.searchLog.length > 0 ? (
                  <>
                    {this.state.searchLog.map(file => (
                      <li key={file.encodedFileID}>
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            this.props.history.push(
                              '/pipeline/rate-lock/' + file.encodedFileID
                            );
                            this.PageLoad(file.encodedFileID);
                          }}
                        >
                          {file.encodedFileID} ({file.borrower})
                        </span>
                      </li>
                    ))}
                  </>
                ) : (
                  <li>
                    <p className="dropdown-item mb-0 disabled">
                      No Recent Files
                    </p>
                  </li>
                )}
              </ul>
            </h1>
          </div>
        </div>

        {this.state.loan?.lockRequested ? (
          <div className="table-responsive my-4">
            {this.state.loan.rateLockConfirmed ? (
              <table
                className="table"
                style={{ fontSize: '14px', width: '1000px' }}
              >
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }} key="ChangeDate">
                      Date
                    </th>
                    <th style={{ textAlign: 'center' }} key="ProgramCode">
                      Loan Program
                    </th>
                    <th style={{ textAlign: 'center' }} key="BaseRate">
                      Base Rate
                    </th>
                    <th style={{ textAlign: 'center' }} key="LLRAs">
                      LLRAs
                    </th>
                    <th style={{ textAlign: 'center' }} key="FinalRate">
                      Final Rate
                    </th>
                    <th style={{ textAlign: 'center' }} key="BasePrice">
                      Base Price
                    </th>
                    <th style={{ textAlign: 'center' }} key="LLPAs">
                      LLPAs
                    </th>
                    <th style={{ textAlign: 'center' }} key="AdjPrice">
                      Adj. Price
                    </th>
                    <th style={{ textAlign: 'center' }} key="LockDays">
                      Lock Days
                    </th>
                    <th style={{ textAlign: 'center' }} key="LockExtFee">
                      Lock Ext. Fee
                    </th>
                    <th style={{ textAlign: 'center' }} key="FinalPrice">
                      Final Price
                    </th>
                    <th style={{ textAlign: 'center' }} key="Credit">
                      Credit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.loan &&
                    this.DisplayRateLockData(this.state.loan.rateEvents)}
                </tbody>
              </table>
            ) : (
              <React.Fragment>
                {this.state.loan && (
                  <React.Fragment>
                    <table
                      className="table"
                      style={{ fontSize: '14px', width: '1000px' }}
                    >
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'center' }} key="ChangeDate">
                            Date
                          </th>
                          <th style={{ textAlign: 'center' }} key="ProgramCode">
                            Loan Program
                          </th>
                          <th style={{ textAlign: 'center' }} key="BaseRate">
                            Base Rate
                          </th>
                          <th style={{ textAlign: 'center' }} key="LLRAs">
                            LLRAs
                          </th>
                          <th style={{ textAlign: 'center' }} key="FinalRate">
                            Final Rate
                          </th>
                          <th style={{ textAlign: 'center' }} key="BasePrice">
                            Base Price
                          </th>
                          <th style={{ textAlign: 'center' }} key="LLPAs">
                            LLPAs
                          </th>
                          <th style={{ textAlign: 'center' }} key="AdjPrice">
                            Adj. Price
                          </th>
                          <th style={{ textAlign: 'center' }} key="LockDays">
                            Lock Days
                          </th>
                          <th style={{ textAlign: 'center' }} key="LockExtFee">
                            Lock Ext. Fee
                          </th>
                          <th style={{ textAlign: 'center' }} key="FinalPrice">
                            Final Price
                          </th>
                          <th style={{ textAlign: 'center' }} key="Credit">
                            Credit
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loan &&
                          this.DisplayRateLockData(this.state.loan.rateEvents)}
                        <tr>
                          <td
                            colSpan={12}
                            style={{ textAlign: 'center', color: 'red' }}
                          >
                            (Rate Lock Request Received - But Not Yet Approved)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {this.state.loan.cicCount !== "0" && (
              <div style={{color:'red', fontSize:'14px', paddingLeft:'10px'}}>
                ({this.state.loan.cicCount}) CICs Approved - Pending Admin Update to Rate Lock Data
              </div>
            )}
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-md-4">
              <p className="border-bottom text-uppercase text-muted mt-2 mt-md-0">
                Loan Details
              </p>
              <div className="row">
                <div className="col-md-4">
                  <small className="text-muted text-capitalize">
                    1st Loan Amount
                  </small>
                  <p>{loan.firstLoan}</p>
                  <small className="text-muted text-capitalize">LTV</small>
                  <p>{loan.ltv ? `${loan.ltv}%` : '---'}</p>
                  <small className="text-muted text-capitalize">
                    Lien Position
                  </small>
                  <p>1st Lien</p>
                  <small className="text-muted text-capitalize">
                    Occupancy Type
                  </small>
                  <p>{this.GetOccupancyType()}</p>
                </div>
                <div className="col-md-4">
                  <small className="text-muted text-capitalize">
                    2nd Loan Amount
                  </small>
                  <p>{loan.secondLoan}</p>
                  <small className="text-muted text-capitalize">CLTV</small>
                  <p>{loan.cltv ? `${loan.cltv}%` : '---'}</p>
                  <small className="text-muted text-capitalize">
                    Loan Purpose
                  </small>
                  <p>{loan.loanPurpose}</p>
                  <small className="text-muted text-capitalize">
                    Document Type
                  </small>
                  <p>
                    {loan.programCode
                      ? loan.programCode.toString().includes('MTGP')
                        ? 'Reduced Doc'
                        : 'Full Doc'
                      : ''}
                  </p>
                </div>
                <div className="col-md-4">
                  <small className="text-muted text-capitalize">
                    Loan Program
                  </small>
                  <p>{loan.programCode}</p>

                  <small className="text-muted text-capitalize">
                    Loan Term
                  </small>
                  <p>{loan.term ? `${loan.term} Years` : '---'}</p>

                  <small className="text-muted text-capitalize">
                    Escrow Account
                  </small>
                  <p>{loan.escrow}</p>

                  {loan.programCode.includes('MTGP2') &&
                    this.GetOccupancyType() === 'Investment' && (
                      <React.Fragment>
                        <small className="text-muted text-capitalize">
                          Prepay Penalty
                        </small>
                        <p>
                          {loan.prepayPenaltyTerm === '---' ||
                          loan.prepayPenaltyTerm === '0'
                            ? 'None'
                            : Number(loan.prepayPenaltyTerm) / 12 + ' Year'}
                        </p>
                      </React.Fragment>
                    )}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <p className="border-bottom text-uppercase text-muted mt-2 mt-md-0">
                Property Details
              </p>
              <div className="row">
                <div className="col-md-6">
                  <small className="text-muted">Appraised Value</small>
                  <p>{loan.propertyValue}</p>
                  <small className="text-muted">Property Type</small>
                  <p>{this.GetPropertyType()}</p>
                </div>
                <div className="col-md-6">
                  <small className="text-muted">Purchase Price</small>
                  <p>{loan.salePrice}</p>
                  <small className="text-muted">County</small>
                  <p>{loan.county}</p>
                </div>
                <div className="col-12">
                  <small className="text-muted">Property Address</small>
                  <p>{defaultAddress}</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <p className="border-bottom text-uppercase text-muted mt-2 mt-md-0">
                Rate Lock Options
              </p>
              <div className="row">
                <div className="col-md-6">
                  <small className="text-muted">Credit Score</small>
                  <p>{loan.creditScore}</p>
                </div>
                <div className="col-md-6">
                  <small className="text-muted">Rate</small>
                  <p>{loan.intRate}</p>
                </div>
              </div>

              <div className="row">
                {loan.programCode.includes('MTGP') ? (
                  <div className="col-md-6">
                    <small className="text-muted">Lock Period</small>
                    <p>30 Days</p>
                  </div>
                ) : (
                  <div className="col-md-6">
                    <label>Lock Period</label>
                    <select
                      className="form-select"
                      error={errors && errors.lockPeriod}
                      id="lockPeriod"
                      name="lockPeriod"
                      value={this.state.lockPeriod}
                      onChange={this.captureInput}
                    >
                      <option key={1} value={1}>
                        15 Days
                      </option>
                      <option key={2} value={2}>
                        30 Days
                      </option>
                    </select>
                  </div>
                )}

                {loan.organizationType === '2' && (
                  <div className="col-md-6">
                    <small className="text-muted">Comp. Type</small>
                    <p>{loan.compType}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {this.state.lockWindowClosed && !this.state.loan?.lockRequested && (
          <div
            className="row"
            style={{ marginTop: '20px', textAlign: 'center', color: 'red' }}
          >
            <div className="text-center">
              <hr />
            </div>
            <table
              style={{ marginTop: '15px', width: '420px', margin: '0 auto' }}
            >
              <tbody>
                <tr>
                  <td>
                    <img
                      src="/images/Lock.png"
                      height="100px"
                      alt="Rates Suspended"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: '700',
                      fontFamily: 'Montserrat',
                      color: 'red',
                      fontSize: '20px',
                      textAlign: 'center',
                    }}
                  >
                    Rate Lock Window Currently Closed
                    <br />
                    <span style={{ fontSize: '12px' }}>
                      (Closed From 4PM Pacific Time Until New RateSheet Uploaded
                      Next Morning)
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="text-center">
              <hr />
            </div>
          </div>
        )}

        {this.state.rateSuspend && !this.state.loan?.lockRequested ? (
          <div
            className="row"
            style={{ marginTop: '20px', textAlign: 'center', color: 'red' }}
          >
            <div className="text-center">
              <hr />
            </div>
            <table
              style={{ marginTop: '15px', width: '420px', margin: '0 auto' }}
            >
              <tbody>
                <tr>
                  <td>
                    <img
                      src="/images/Lock.png"
                      height="100px"
                      alt="Rates Suspended"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: '700',
                      fontFamily: 'Montserrat',
                      color: 'red',
                      fontSize: '20px',
                      textAlign: 'center',
                    }}
                  >
                    Rates Currently Suspended
                    <br />
                    <span style={{fontSize:'16px'}}>({this.state.rateSuspend === 'Suspend' ? 'New Ratesheet Is Being Prepared' : 'Lock Desk Has Closed Early Today'})</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="text-center">
              <hr />
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className="row mt-4">
              {this.state.programList && (
                <React.Fragment>
                  {this.state.loan?.lockRequested && (
                    <div className="col-12 my-4">
                      <div role="alert" className= {(this.state.loan.cancelled && 
                        !this.state.loan.rateEvents[0].timestampPlus30.endsWith('*')) ? 
                        "alert alert-danger" : "alert alert-warning"}
                      >
                        <p className="mb-0">
                          {this.state.loan.rateLockDate === '---' ? (
                            <React.Fragment>
                              Rate Lock Requested:{' '}
                              {
                                this.state.loan.rateEvents[
                                  this.state.loan.rateEvents.length - 1
                                ].timestamp
                              }
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {this.state.loan.cancelled ? (
                                <span>
                                  Rate Lock Cancelled: (<span style={{color:'red'}}>{this.state.loan.rateEvents[0].timestamp.substr(0,8)}</span>)
                                  {this.state.loan.rateEvents[0].timestampPlus30.endsWith('*') ? (
                                    <span style={{marginLeft:'50px'}}>A New Rate Lock Can Be Requested (30+ Days Passed)</span>
                                  ) : (
                                    <span style={{marginLeft:'50px'}}>Rate Lock Unavailable Until: (<span style={{color:'red'}}>{this.state.loan.rateEvents[0].timestampPlus30.substr(0,8)}</span>)</span>
                                  )}
                                </span>
                              ) : (
                                <span>Rate Lock Start: {this.state.loan.rateLockDate}</span>
                              )}
                              
                            </React.Fragment>
                          )}
                        </p>
                      </div>
                    </div>
                  )}

                  {(this.state.loan.cancelled && !this.state.loan.rateEvents[0].timestampPlus30.endsWith('*')) ? (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <img src="/images/Cancelled.png" alt="" height="240px" width="260px" />
                    </div>
                  ) : (
                    <React.Fragment>
                      {!this.state.loan?.lockRequested &&
                        LoginState.userName === 'echo' && (
                          <div class="mb-4">
                            <p className="border-bottom text-uppercase text-muted mt-2 mt-md-0">
                              Rate Lock Time{' '}
                              <span
                                style={{
                                  fontSize: '14px',
                                  color: 'red',
                                  marginLeft: '10px',
                                }}
                              >
                                (Submit in the Past)
                              </span>
                            </p>
                            <input
                              type="datetime-local"
                              name="lockTime"
                              value={this.state.lockTime}
                              onChange={this.captureInput}
                            />

                            <button
                              className="btn btn-primary"
                              disabled={
                                this.state.selectedTime === this.state.lockTime
                              }
                              onClick={() => this.UpdateTime()}
                              style={{ marginLeft: '20px' }}
                            >
                              Update
                            </button>
                          </div>
                      )}

                      {this.state.noProgramReason && (
                        <div className="text-center mt-4 mb-4">
                          <ProgramList
                            allPrograms={[]}
                            availablePrograms={[]}
                            progErr={this.state.noProgramReason}
                          />
                        </div>
                      )}

                      {!this.state.noProgramReason &&
                        this.state.programList?.length > 0 && (
                          <React.Fragment>
                            <div className="col-12 col-lg-6" ref={this.programsRef}>
                              <p className="border-bottom text-uppercase text-muted mt-2 mt-md-0">
                                Selected Program{' '}
                                {this.state.selectedTime ? (
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      color: 'red',
                                      marginLeft: '10px',
                                    }}
                                  >
                                    {'(' +
                                      this.displayTime(this.state.selectedTime) +
                                      ')'}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </p>
                              <ProgramList
                                allPrograms={this.state.allPrograms}
                                availablePrograms={this.state.programList}
                                adjustmentList={this.state.rateAdjustments}
                                selectedProgram={selectedProgram}
                                onSelect={program =>
                                  this.setState({ selectedProgram: program }, () =>
                                    window.scrollTo(0, document.body.scrollHeight)
                                  )
                                }
                              />
                              <br />
                              <p className="border-bottom text-uppercase text-muted mt-2 mt-md-0">
                                Adjustments
                              </p>
                              {this.state.programList &&
                              this.state.programList.length > 0 ? (
                                <Adjustments
                                  rateAdjustments={selectedProgram?.rateAdjustments}
                                  priceAdjustments={
                                    selectedProgram?.priceAdjustments
                                  }
                                />
                              ) : (
                                <div
                                  className="alert alert-secondary py-1 px-2"
                                  role="alert"
                                >
                                  No Adjustments Found
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-lg-6">
                              {this.state.selectedProgram && (
                                <>
                                  <p className="border-bottom text-uppercase text-muted mt-2 mt-md-0">
                                    Select Rate
                                    {selectedProgram && (
                                      <span
                                        style={{
                                          fontSize: '14px',
                                          color: 'red',
                                          marginLeft: '10px',
                                        }}
                                      >
                                        {'(Rate Premium Cap: ' +
                                          selectedProgram.rateCap +
                                          ')'}
                                      </span>
                                    )}
                                    {loan.organizationType === '2' &&
                                      selectedProgram &&
                                      loan.compType !== 'Borrower Paid' && (
                                        <span
                                          style={{
                                            fontSize: '14px',
                                            color: 'red',
                                            marginLeft: '10px',
                                          }}
                                        >
                                          {'(Lender Paid - ' +
                                            loan.compType +
                                            ' = '}
                                          <span style={{ color: 'green' }}>
                                            {formatCurrency2(
                                              (Number(
                                                loan.compType
                                                  .split('$')[1]
                                                  .split('%')[0]
                                              ) *
                                                Number(
                                                  unformatCurrency(loan.firstLoan)
                                                )) /
                                                100
                                            )}
                                          </span>
                                          {')'}
                                        </span>
                                      )}
                                  </p>
                                  <ProgramRates
                                    programCode={selectedProgram?.code}
                                    programTerm={selectedProgram?.term}
                                    rateCap={selectedProgram.rateCap}
                                    programRates={selectedProgram?.rateList}
                                    rateAdjustments={
                                      selectedProgram?.rateAdjustments
                                    }
                                    priceAdjustments={
                                      selectedProgram?.priceAdjustments
                                    }
                                    firstLoan={loan.firstLoan}
                                    chooseRate={this.handleChange}
                                    selectedRate={this.state.selectedRate}
                                  />
                                </>
                              )}
                            </div>
                            {(this.state.loan?.lockRequested && !this.state.loan.cancelled) ? (
                              <div>
                                <button
                                  className="btn btn-primary"
                                  disabled={
                                    !selectedProgram ||
                                    this.state.rateSuspend ||
                                    (loan.programCode === selectedProgram.code &&
                                      loan.rateEvents[0].finalRate === selectedRate)
                                  }
                                  onClick={() => this.RequestCIC()}
                                  style={{ marginTop: '20px' }}
                                >
                                  Request CIC
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button
                                  className="btn btn-primary"
                                  disabled={
                                    !this.state.rateClicked ||
                                    !LoginState.userRole.toLowerCase().includes('lock') && 
                                    (this.state.rateSuspend || this.state.lockWindowClosed)
                                  }
                                  onClick={() => this.RequestRateLock()}
                                  style={{ marginTop: '20px' }}
                                >
                                  Request Rate Lock
                                </button>
                              </div>
                            )}
                          </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              <Modal
                width="400px"
                height="580px"
                title="Confirm Rate Lock Request"
                show={this.state.showConfirmModal}
                onAccept={() => this.SendChangeNotifications()}
                onDecline={() => this.setState({ showConfirmModal: false })}
                buttonMessage="Submit"
              >
                {this.ShowConfirmModal()}
              </Modal>
              <Modal
                width="450px"
                height="240px"
                title="Confirm CIC Request"
                show={this.state.showCicModal}
                onAccept={() => this.SendCicRequest()}
                onDecline={() => this.setState({ showCicModal: false })}
                buttonMessage="Submit"
              >
                {this.ShowCicModal()}
              </Modal>
              <ModalWait
                title="Submitting Request. Please Wait!"
                show={this.state.isUploading}
              />
              {this.state.successMessage && (
                <Toast
                  type="success"
                  title={this.state.successMessage}
                  onClose={() => this.setState({ successMessage: null })}
                />
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(RateLock);
